/*=========================================================================================
  File Name: state.js
  Description: Vuex Store - state
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import navbarSearchAndPinList from "@/layouts/components/navbar/navbarSearchAndPinList"
import themeConfig from "@/../themeConfig.js"
import colors from "@/../themeConfig.js"

// /////////////////////////////////////////////
// Helper
// /////////////////////////////////////////////

// *From Auth - Data will be received from auth provider
// const userDefaults = {
//   uid         : 0,          // From Auth
//   displayName : 'John Doe', // From Auth
//   about       : 'Dessert chocolate cake lemon drops jujubes. Biscuit cupcake ice cream bear claw brownie brownie marshmallow.',
//   photoURL    : require('@/assets/images/portrait/small/avatar-s-11.jpg'), // From Auth
//   status      : 'online',
//   userRole    : 'admin'
// }

// const userInfoLocalStorage = JSON.parse(localStorage.getItem('userInfo')) || {}
//
// // Set default values for active-user
// // More data can be added by auth provider or other plugins/packages
// const getUserInfo = () => {
//   const userInfo = {}
//
//   // Update property in user
//   Object.keys(userDefaults).forEach((key) => {
//     // If property is defined in localStorage => Use that
//     userInfo[key] = userInfoLocalStorage[key] ?  userInfoLocalStorage[key] : userDefaults[key]
//   })
//
//   // Include properties from localStorage
//   Object.keys(userInfoLocalStorage).forEach((key) => {
//     if (userInfo[key] === undefined && userInfoLocalStorage[key] !== null) userInfo[key] = userInfoLocalStorage[key]
//   })
//
//   return userInfo
// }

// Check if device is touch device
// This is used to remove perfect scrollbar from touch devices
// Using Dynamic components
const is_touch_device = () => {
  var prefixes = " -webkit- -moz- -o- -ms- ".split(" ")
  var mq = function (query) {
    return window.matchMedia(query).matches
  }

  if ("ontouchstart" in window || window.DocumentTouch) {
    return true
  }

  // include the 'heartz' as a way to have a non matching MQ to help terminate the join
  // https://git.io/vznFH
  var query = ["(", prefixes.join("touch-enabled),("), "heartz", ")"].join("")
  return mq(query)
}

// /////////////////////////////////////////////
// State
// /////////////////////////////////////////////

const state = {
  // AppActiveUser           : getUserInfo(),
  bodyOverlay: false,
  isVerticalNavMenuActive: true,
  is_touch_device: is_touch_device(),
  mainLayoutType: themeConfig.mainLayoutType || "vertical",
  navbarSearchAndPinList: navbarSearchAndPinList,
  reduceButton: themeConfig.sidebarCollapsed,
  verticalNavMenuWidth: "default",
  verticalNavMenuItemsMin: false,
  scrollY: 0,
  starredPages: navbarSearchAndPinList["pages"].data.filter((page) => page.is_bookmarked),
  theme: themeConfig.theme || "light",
  themePrimaryColor: colors.primary,

  // Can be used to get current window with
  // Note: Above breakpoint state is for internal use of sidebar & navbar component
  windowWidth: null,

  timezones: [
    {
      value: "-720,0",
      caption: "(-12:00) International Date Line West",
      timezone: "Pacific/Pago_Pago",
    },
    {
      value: "-660,0",
      caption: "(-11:00) Midway Island, Samoa",
      timezone: "Pacific/Pago_Pago",
    },
    {
      value: "-600,0",
      caption: "(-10:00) Hawaii",
      timezone: "Pacific/Honolulu",
    },
    {
      value: "-540,1",
      caption: "(-09:00) Alaska",
      timezone: "America/Nome",
    },
    {
      value: "-480,1",
      caption: "(-08:00) Pacific Time (US & Canada)",
      timezone: "America/Los_Angeles",
    },
    {
      value: "-420,0",
      caption: "(-07:00) Arizona",
      timezone: "America/Phoenix",
    },
    {
      value: "-420,1",
      caption: "(-07:00) Mountain Time (US & Canada)",
      timezone: "America/Denver",
    },
    {
      value: "-360,0",
      caption: "(-06:00) Central America, Saskatchewan",
      timezone: "America/Regina",
    },
    {
      value: "-360,1",
      caption: "(-06:00) Central Time (US & Canada), Guadalajara, Mexico city",
      timezone: "America/Mexico_City",
    },
    {
      value: "-300,0",
      caption: "(-05:00) Indiana, Bogota, Lima, Quito, Rio Branco",
      timezone: "America/Bogota",
    },
    {
      value: "-300,1",
      caption: "(-05:00) Eastern time (US & Canada)",
      timezone: "America/New_York",
    },
    {
      value: "-240,1",
      caption: "(-04:00) Atlantic time (Canada), Manaus, Santiago",
      timezone: "America/Santiago",
    },
    {
      value: "-240,0",
      caption: "(-04:00) Caracas, La Paz",
      timezone: "America/Caracas",
    },
    {
      value: "-210,1",
      caption: "(-03:30) Newfoundland",
      timezone: "America/St_Johns",
    },
    {
      value: "-180,1",
      caption: "(-03:00) Greenland, Brasilia, Montevideo",
      timezone: "America/Montevideo",
    },
    {
      value: "-180,0",
      caption: "(-03:00) Buenos Aires, Georgetown",
      timezone: "America/Argentina/Buenos_Aires",
    },
    {
      value: "-60,1",
      caption: "(-01:00) Azores",
      timezone: "Atlantic/Azores",
    },
    {
      value: "-60,0",
      caption: "(-01:00) Cape Verde Is.",
      timezone: "Atlantic/Cape_Verde",
    },
    {
      value: "0,0",
      caption: "(00:00) Monrovia, Reykjavik",
      timezone: "Etc/GMT",
    },
    {
      value: "0,1",
      caption: "(00:00) GMT: Dublin, Edinburgh, Lisbon, London, Casablanca",
      timezone: "Europe/Dublin",
    },
    {
      value: "60,1",
      caption: "(+01:00) Amsterdam, Berlin, Rome, Vienna, Prague, Brussels",
      timezone: "Europe/Rome",
    },
    {
      value: "60,0",
      caption: "(+01:00) West Central Africa",
      timezone: "Africa/Algiers",
    },
    {
      value: "120,1",
      caption: "(+02:00) Amman, Athens, Beirut, Jerusalem",
      timezone: "Europe/Athens",
    },
    {
      value: "120,0",
      caption: "(+02:00) Cairo, Harare, Pretoria",
      timezone: "Africa/Harare",
    },
    {
      value: "180,0",
      caption: "(+03:00) Istanbul, Kuwait, Riyadh, Nairobi, Tbilisi",
      timezone: "Europe/Istanbul",
    },
    {
      value: "180,1",
      caption: "(+03:00) Baghdad, Moscow, St. Petersburg, Volgograd",
      timezone: "Asia/Baghdad",
    },
    {
      value: "210,1",
      caption: "(+03:30) Tehran",
      timezone: "Asia/Tehran",
    },
    {
      value: "240,0",
      caption: "(+04:00) Abu Dhabi, Baku, Yerevan, Muscat",
      timezone: "Asia/Baku",
    },
    {
      value: "270,0",
      caption: "(+04:30) Kabul",
      timezone: "Asia/Kabul",
    },
    {
      value: "300,1",
      caption: "(+05:00) Ekaterinburg",
      timezone: "Asia/Samarkand",
    },
    {
      value: "300,0",
      caption: "(+05:00) Islamabad, Karachi, Tashkent",
      timezone: "Asia/Karachi",
    },
    {
      value: "330,0",
      caption: "(+05:30) Chennai, Kolkata, Mumbai, New Delhi, Sri Jayawardenepura",
      timezone: "Asia/Kolkata",
    },
    {
      value: "345,0",
      caption: "(+05:45) Kathmandu",
      timezone: "Asia/Kathmandu",
    },
    {
      value: "360,0",
      caption: "(+06:00) Astana, Dhaka",
      timezone: "Asia/Dhaka",
    },
    {
      value: "360,1",
      caption: "(+06:00) Almaty, Nonosibirsk",
      timezone: "Asia/Almaty",
    },
    {
      value: "390,0",
      caption: "(+06:30) Yangon (Rangoon)",
      timezone: "Asia/Rangoon",
    },
    {
      value: "420,1",
      caption: "(+07:00) Krasnoyarsk",
      timezone: "Asia/Krasnoyarsk",
    },
    {
      value: "420,0",
      caption: "(+07:00) Bangkok, Hanoi, Jakarta",
      timezone: "Asia/Bangkok",
    },
    {
      value: "480,0",
      caption: "(+08:00) Beijing, Hong Kong, Singapore, Taipei, Perth",
      timezone: "Asia/Singapore",
    },
    {
      value: "480,1",
      caption: "(+08:00) Irkutsk, Ulaan Bataar",
      timezone: "Asia/Irkutsk",
    },
    {
      value: "540,1",
      caption: "(+09:00) Yakutsk",
      timezone: "Asia/Yakutsk",
    },
    {
      value: "540,0",
      caption: "(+09:00) Seoul, Osaka, Sapporo, Tokyo",
      timezone: "Asia/Seoul",
    },
    {
      value: "570,0",
      caption: "(+09:30) Darwin",
      timezone: "Australia/Darwin",
    },
    {
      value: "570,1",
      caption: "(+09:30) Adelaide",
      timezone: "Australia/Adelaide",
    },
    {
      value: "600,0",
      caption: "(+10:00) Brisbane, Guam, Port Moresby",
      timezone: "Australia/Brisbane",
    },
    {
      value: "600,1",
      caption: "(+10:00) Canberra, Melbourne, Sydney, Hobart, Vladivostok",
      timezone: "Australia/Currie",
    },
    {
      value: "660,0",
      caption: "(+11:00) Magadan, Solomon Is., New Caledonia",
      timezone: "Asia/Magadan",
    },
    {
      value: "720,1",
      caption: "(+12:00) Auckland, Wellington",
      timezone: "Pacific/Auckland",
    },
    {
      value: "720,0",
      caption: "(+12:00) Fiji, Kamchatka, Marshall Is.",
      timezone: "Pacific/Fiji",
    },
    {
      value: "780,0",
      caption: "(+13:00) Nuku'alofa",
      timezone: "Pacific/Fakaofo",
    },
  ],
  languages: [
    { value: "afr", caption: "Afrikaans" },
    { value: "ak", caption: "Akan" },
    { value: "am", caption: "Amharic" },
    { value: "ar-DZ", caption: "Arabic (Algeria)" },
    { value: "ar-BH", caption: "Arabic (Bahrain)" },
    { value: "ar-TD", caption: "Arabic (Chad)" },
    { value: "ar-KM", caption: "Arabic (Comoros)" },
    { value: "ar-DJ", caption: "Arabic (Djibouti)" },
    { value: "ar-EG", caption: "Arabic (Egypt)" },
    { value: "ar-ER", caption: "Arabic (Eritrea)" },
    { value: "ar-IQ", caption: "Arabic (Iraq)" },
    { value: "ar-IL", caption: "Arabic (Israel)" },
    { value: "ar-JO", caption: "Arabic (Jordan)" },
    { value: "ar-KW", caption: "Arabic (Kuwait)" },
    { value: "ar-LB", caption: "Arabic (Lebanon)" },
    { value: "ar-LY", caption: "Arabic (Libya)" },
    { value: "ar-MR", caption: "Arabic (Mauritania)" },
    { value: "ar-MA", caption: "Arabic (Morocco)" },
    { value: "ar-OM", caption: "Arabic (Oman)" },
    { value: "ar-QA", caption: "Arabic (Qatar)" },
    { value: "ar-SA", caption: "Arabic (Saudi Arabia)" },
    { value: "ar-SO", caption: "Arabic (Somalia)" },
    { value: "ar-SD", caption: "Arabic (Sudan)" },
    { value: "ar-SY", caption: "Arabic (Syria)" },
    { value: "ar-TN", caption: "Arabic (Tunisia)" },
    { value: "ar-AE", caption: "Arabic (United Arab Emirates)" },
    { value: "ar-EH", caption: "Arabic (Western Sahara)" },
    { value: "ar-YE", caption: "Arabic (Yemen)" },
    { value: "hy", caption: "Armenian" },
    { value: "as-IN", caption: "Assamese" },
    { value: "awa", caption: "Awadhi" },
    { value: "az-AZ", caption: "Azerbaijani" },
    { value: "bal", caption: "Balochi" },
    { value: "be-BY", caption: "Belarusian" },
    { value: "bn-IN", caption: "Bengali (Bangla)" },
    { value: "bho", caption: "Bhojpuri" },
    { value: "bul", caption: "Bulgarian" },
    { value: "my", caption: "Burmese" },
    { value: "ca-ES", caption: "Catalan" },
    { value: "ceb", caption: "Cebuano (Visayan)" },
    { value: "ny", caption: "Chewa" },
    { value: "hne", caption: "Chhattisgarhi" },
    { value: "zh", caption: "Chinese" },
    { value: "zh-HK", caption: "Chinese (Hong Kong)" },
    { value: "zh-CN", caption: "Chinese (Simplified)" },
    { value: "zh-MO", caption: "Chinese (Traditional)" },
    { value: "ctg", caption: "Chittagonian" },
    { value: "hr-HR", caption: "Croatian" },
    { value: "cs-CZ", caption: "Czech" },
    { value: "da-DK", caption: "Danish" },
    { value: "dcc", caption: "Deccan" },
    { value: "dhd", caption: "Dhundhari" },
    { value: "nl-NL", caption: "Dutch" },
    { value: "en-AU", caption: "English (Australian)" },
    { value: "en-CA", caption: "English (Canadian)" },
    { value: "en-IN", caption: "English (Indian)" },
    { value: "en-US", caption: "English (U.S.)" },
    { value: "en-GB", caption: "English (UK)" },
    { value: "en-EN", caption: "English (UK)" },
    { value: "ee", caption: "Ewe" },
    { value: "fi-FI", caption: "Finnish" },
    { value: "fr-FR", caption: "French" },
    { value: "fr-CA", caption: "French (Canadian)" },
    { value: "ff", caption: "Fula" },
    { value: "gaa", caption: "Ga" },
    { value: "gan", caption: "Gan Chinese" },
    { value: "de-DE", caption: "German" },
    { value: "el-GR", caption: "Greek" },
    { value: "gu-IN", caption: "Gujarati" },
    { value: "ht", caption: "Haitian Creole" },
    { value: "hak", caption: "Hakka" },
    { value: "bgc", caption: "Haryanvi" },
    { value: "ha-NG", caption: "Hausa" },
    { value: "he-IL", caption: "Hebrew" },
    { value: "hil", caption: "Hiligaynon/Ilonggo (Visayan)" },
    { value: "hi-IN", caption: "Hindi" },
    { value: "hmn", caption: "Hmong" },
    { value: "hu-HU", caption: "Hungarian" },
    { value: "ig-NG", caption: "Igbo" },
    { value: "ilo", caption: "Ilocano" },
    { value: "id-ID", caption: "Indonesian" },
    { value: "it-IT", caption: "Italian" },
    { value: "ja-JP", caption: "Japanese" },
    { value: "jv", caption: "Javanese" },
    { value: "cjy", caption: "Jin" },
    { value: "kn-IN", caption: "Kannada" },
    { value: "kk-KZ", caption: "Kazakh" },
    { value: "km-KH", caption: "Khmer" },
    { value: "rw-RW", caption: "Kinyarwanda" },
    { value: "rn", caption: "Kirundi" },
    { value: "kok-IN", caption: "Konkani" },
    { value: "ko-KR", caption: "Korean" },
    { value: "ku", caption: "Kurdish" },
    { value: "mad", caption: "Madurese" },
    { value: "mag", caption: "Magahi" },
    { value: "mai", caption: "Maithili" },
    { value: "mk", caption: "Macedonian" },
    { value: "mg", caption: "Malagasy" },
    { value: "ms-BN", caption: "Malay" },
    { value: "ml-IN", caption: "Malayalam" },
    { value: "mr-IN", caption: "Marathi" },
    { value: "mwr", caption: "Marwari" },
    { value: "mos", caption: "Mossi" },
    { value: "ne-NP", caption: "Nepali" },
    { value: "nb-NO", caption: "Norwegian (Bokmal)" },
    { value: "or-IN", caption: "Odia (Oriya)" },
    { value: "om", caption: "Oromo" },
    { value: "ps-AF", caption: "Pashto" },
    { value: "fa-IR", caption: "Persian" },
    { value: "pl-PL", caption: "Polish" },
    { value: "pt-BR", caption: "Portuguese (Brazil)" },
    { value: "pt-PT", caption: "Portuguese (Portugal)" },
    { value: "pa-IN", caption: "Punjabi" },
    { value: "qu-PE", caption: "Quechua" },
    { value: "ro-RO", caption: "Romanian" },
    { value: "ru-RU", caption: "Russian" },
    { value: "skr", caption: "Saraiki" },
    { value: "sr", caption: "Serbian" },
    { value: "sn", caption: "Shona" },
    { value: "sd", caption: "Sindhi" },
    { value: "si", caption: "Sinhalese" },
    { value: "sk-SK", caption: "Slovak" },
    { value: "so", caption: "Somali" },
    { value: "nan", caption: "Southern Min (incl. Hokkien and Teochew)" },
    { value: "es", caption: "Spanish" },
    { value: "es-AR", caption: "Spanish (Argentina)" },
    { value: "es-BO", caption: "Spanish (Bolivia)" },
    { value: "es", caption: "Spanish (Castilian)" },
    { value: "es-CL", caption: "Spanish (Chile)" },
    { value: "es-CO", caption: "Spanish (Colombia)" },
    { value: "es-CR", caption: "Spanish (Costa Rica)" },
    { value: "es-DO", caption: "Spanish (Dominican Republic)" },
    { value: "es-EC", caption: "Spanish (Ecuador)" },
    { value: "es-SV", caption: "Spanish (El Salvador)" },
    { value: "es-GT", caption: "Spanish (Guatemala)" },
    { value: "es-HN", caption: "Spanish (Honduras)" },
    { value: "es-MX", caption: "Spanish (Mexico)" },
    { value: "es-NI", caption: "Spanish (Nicaragua)" },
    { value: "es-PA", caption: "Spanish (Panama)" },
    { value: "es-PY", caption: "Spanish (Paraguay)" },
    { value: "es-PE", caption: "Spanish (Peru)" },
    { value: "es-PR", caption: "Spanish (Puerto Rico)" },
    { value: "es-ES", caption: "Spanish (Spain)" },
    { value: "es-UY", caption: "Spanish (Uruguay)" },
    { value: "es-VE", caption: "Spanish (Venezuela)" },
    { value: "su", caption: "Sundanese" },
    { value: "sw", caption: "Swahili" },
    { value: "sv-FI", caption: "Swedish" },
    { value: "syl", caption: "Sylheti" },
    { value: "fil-PH", caption: "Tagalog (Filipino)" },
    { value: "ta-IN", caption: "Tamil" },
    { value: "te-IN", caption: "Telugu" },
    { value: "th-TH", caption: "Thai" },
    { value: "tr-TR", caption: "Turkish" },
    { value: "tk-TM", caption: "Turkmen" },
    { value: "tw", caption: "Twi" },
    { value: "uk-UA", caption: "Ukrainian" },
    { value: "ur-PK", caption: "Urdu" },
    { value: "ug-CN", caption: "Uyghur" },
    { value: "uz-UZ", caption: "Uzbek" },
    { value: "vi-VN", caption: "Vietnamese" },
    { value: "wuu", caption: "Wu" },
    { value: "xh-ZA", caption: "Xhosa" },
    { value: "hsn", caption: "Xiang (Hunanese)" },
    { value: "yo-NG", caption: "Yoruba" },
    { value: "yue", caption: "Yue (incl. Cantonese)" },
    { value: "za", caption: "Zhuang" },
    { value: "zu-ZA", caption: "Zulu" },

    { caption: "Gikuyu" },
    { caption: "Kikamba" },
    { caption: "Luhya" },
    { caption: "Meru" },
    { caption: "Gusii" },
    { caption: "Embu" },
    { caption: "Mijikenda" },
    { caption: "Dholuo" },
    { caption: "Kalenjin" },
    { caption: "Maasai" },
    { caption: "Turkana" },
    { caption: "Borana" },
    { caption: "Orma" },
    { caption: "Rendille" },
  ],
}

export default state
